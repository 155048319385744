<script setup lang="ts">
import { computed } from 'vue'
import bottomSheet from '/~/core/bottom-sheet'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useLocalization } from '/~/composables/localization'

const props = withDefaults(
  defineProps<{
    method: any
  }>(),
  { method: () => ({}) }
)

const { translate } = useLocalization()

const isVerified = computed(
  () => props.method.status?.toLowerCase() === 'verified'
)

function showOptions() {
  bottomSheet.show('withdraw-options', {
    to: 'menu-modal',
    props: {
      method: props.method,
    },
  })
}

function initVerification() {
  if (isVerified.value) {
    return
  }
  bottomSheet.show('method-verify', {
    to: 'menu-modal',
    props: {
      method: props.method,
    },
  })
}
</script>

<template>
  <div>
    <div
      class="relative rounded-lg border bg-white px-4 py-2"
      :class="{
        'cursor-pointer border-error': !isVerified,
      }"
      @click="initVerification"
    >
      <div class="flex w-full items-center justify-between space-x-6">
        <div
          class="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-black"
        >
          <base-icon :svg="'v2/custom/bank'" size="md" class="text-white" />
        </div>
        <div class="flex w-1/3 flex-1 flex-col gap-y-0.5">
          <p class="truncate font-bold text-eonx-neutral-800">
            {{ method.accountName }}
          </p>
          <p class="truncate text-sm text-eonx-neutral-800">
            {{
              translate('bankAccount.details', {
                acc: method.accountNumber,
                bsb: method.bsb,
              })
            }}
          </p>
        </div>
        <div
          class="flex whitespace-nowrap text-right font-bold text-eonx-neutral-800"
        >
          <base-button
            v-if="isVerified"
            icon="heroicons/outline/ellipsis-vertical"
            alt="close"
            :size="28"
            :padding="2"
            @click="showOptions"
          />
        </div>
      </div>
    </div>
    <div
      v-if="!isVerified"
      class="mt-2 rounded-lg bg-error px-5 py-4 text-sm text-fg-error"
    >
      To withdraw to this account, you need to verify it first.
    </div>
  </div>
</template>
